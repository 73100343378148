//==============================================================================
//==============================================================================
import * as React from 'react';
import { HtmlHeadInclude } from '@msdyn365-commerce/core-internal';
import { IOneTrustProps } from './one-trust.props.autogenerated';

//==============================================================================
// CONSTANTS
//==============================================================================
const SCRIPT_SRC = (id: string) => `https://cdn.cookielaw.org/consent/${id}/OtAutoBlock.js`;
const TEMPLATE_SRC = 'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js';

//==============================================================================
// CLASS DEFINITION
//==============================================================================

/**
 * OneTrust component
 * @extends {React.PureComponent<IOneTrustProps<IOneTrustData>>}
 */
class OneTrust extends React.PureComponent<IOneTrustProps<{}>> {

    //----------------------------------------------------------
    //----------------------------------------------------------
    public render(): JSX.Element | null {
        const config = this.props.config;

        // Make sure logging is enabled
        if (!config.isEnabled || !config.envId) {
            return null;
        }

        // Load the scripts, then run the init code.
        return (
            <HtmlHeadInclude>
                <script data-load-point='headStart' src={SCRIPT_SRC(config.envId)}></script>
                <script data-load-point='headStart' src={TEMPLATE_SRC} data-domain-script={config.envId}></script>
                <script data-load-point='headStart' dangerouslySetInnerHTML={{ __html: this.createInitScript() }} />
            </HtmlHeadInclude>
        );
    }

    //----------------------------------------------------------
    //----------------------------------------------------------
    private createInitScript() {
        return `function OptanonWrapper() {}`;
    }
}

export default OneTrust;
