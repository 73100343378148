const binding = { modules: {}, dataActions: {} };

    (binding.modules['one-trust'] = {
        c: () => require('partner/modules/one-trust/one-trust.tsx'),
        $type: 'scriptModule',
        da: [],
        
        iNM: false,
        ns: '__local__',
        n: 'one-trust',
        p: '__local__',
        
        pdp: '',
        
        
        md: 'src/modules/one-trust'
    });
        

        
    window.__bindings__ = window.__bindings__ || {};
    window.__bindings__.modules = {
        ...window.__bindings__.modules || {},
        ...binding.modules
    };
    
        window.__bindings__.dataActions = {
        ...window.__bindings__.dataActions || {},
        ...binding.dataActions
    };